import React from 'react'
import Layout from '../components/Layout'
import { NotificationContainer } from 'react-notifications'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/contact-background.jpg'
import {
  TitleSection,
  SectionContainer,
} from '../components/common/Index.styled'
import Divider from '../components/common/Divider'
import Section from '../components/common/Section'
import ContactForm from '../components/ContactForm'
import { FormattedMessage } from 'react-intl'

const Contacto = props => (
  <Layout
    title="Contacto"
    description="No pierdas la oportundiad y registrate gratis con nostros."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="70% 65%" />
    <TitleSection>
      <FormattedMessage id="contact.title" />
    </TitleSection>
    <Divider border="right">
      <div style={{ paddingRight: '2rem', maxWidth: '800px' }}>
        <h2>
          <FormattedMessage id="contact.divider_title" />
        </h2>
        <p style={{ fontSize: '1.2rem' }}>
          <FormattedMessage id="contact.divider_description" />
        </p>
      </div>
    </Divider>
    <Section height="550px">
      <SectionContainer>
        <ContactForm color="blue" />
      </SectionContainer>
      <SectionContainer>
        <iframe
          title="Ubicación AS Consulting Group"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.2313204222446!2d-99.15018578498973!3d19.402408846672824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff1dce0f1ce3%3A0x51af0265d2e3e271!2sObrero%20Mundial%20644%2C%20Atenor%20Salas%2C%20Benito%20Ju%C3%A1rez%2C%2003010%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1631379085832!5m2!1ses-419!2smx"
          height="450"
          frameBorder="0"
          style={{
            border: 0,
            width: '100%',
            maxWidth: 550,
            marginRight: 32,
          }}
          allowFullScreen
        />
        <iframe
          title="AS Consulting Group Mty"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14385.827138123597!2d-100.364852!3d25.6561485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bdb2c3214d4f%3A0xc0dfdfb711e2a3ce!2sAS%20Consulting%20Group!5e0!3m2!1sen!2smx!4v1720622761271!5m2!1sen!2smx"
          height="450"
          frameBorder="0"
          style={{
            border: 0,
            width: '100%',
            maxWidth: 550,
          }}
          allowFullScreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </SectionContainer>
    </Section>

    <NotificationContainer />
  </Layout>
)

export default Contacto
