import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { createNotification } from '../../utils/createNotification'
import { contactSchema } from '../../utils/schemas'

import FormFields from '../common/FormFields'
import { Row } from 'react-styled-flexboxgrid'

class ContactForm extends Component {
  static propTypes = {
    actionAfterSubmit: PropTypes.func,
  }

  static defaultProps = {
    actionAfterSubmit: () => {},
  }
  state = {
    loading: false,
    clearValues: false,
  }
  handleSubmit = form => {
    this.setState({ loading: true }, async () => {
      try {
        await axios({
          method: 'post',
          baseURL: process.env.API_CONTACT_BASE_URL,
          url: '/submit',
          data: { ...form },
          auth: {
            username: 'ASConsulting_Group',
            password: process.env.API_PASSWORD_SEND_EMAIL,
          },
        })
        createNotification('success', {
          title: 'Enviado correctamente!',
          message: '¡Se ha enviado con exito!',
        })
        this.setState(
          {
            loading: false,
            clearValues: !this.state.clearValues,
          },
          () => this.props.actionAfterSubmit()
        )
      } catch (err) {
        console.log('Error', err)
        createNotification('error', {
          title: 'Hubo un error',
          message: err.message,
        })
        this.setState({ loading: false })
      }
    })
  }
  render() {
    const { color = '' } = this.props
    return (
      <Row
        center="xs"
        middle="xs"
        style={{ padding: '2rem', minWidth: '350px' }}
      >
        <FormFields
          submit={this.handleSubmit}
          fields={contactSchema}
          buttonText="button_send"
          clearValues={this.state.clearValues}
          color={color}
        />
      </Row>
    )
  }
}

export default ContactForm
